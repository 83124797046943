<template>
    <div class="login-body">
        <div class="login-image">
            <img :src="'layout/images/pages/login-' + loginColor + '.png'" alt="atlantis" />
        </div>
        <div class="login-panel p-fluid">
            <div class="flex flex-column">
                <div class="flex align-items-center mb-6 logo-container">
                    <img :src="'layout/images/logo-' + color + '.png'" class="login-logo" />
                    <img :src="'layout/images/appname-' + color + '.png'" class="login-appname" />
                </div>
                <div class="form-container">
                    <span class="p-input-icon-left">
                        <i class="pi pi-envelope"></i>
                        <InputText v-model="email" type="text" placeholder="Email" @keyup.enter="submit" />
                    </span>
                        <Password v-model="password" toggleMask :feedback="false" placeholder=" Password" @keyup.enter="login" class="password"/>
                    <a href="#" class="flex" v-if="itsNotProduction">Password dimenticata?</a>
                </div>
                <div class="button-container">
                    <Button type="button" label="Login" @click="login"></Button>
                    <span v-if="itsNotProduction">Non hai un account?<a>Registrati</a></span>
                </div>
            </div>

            <div class="login-footer flex align-items-center">
                <!-- <div class="flex align-items-center login-footer-logo-container">
                    <img src="layout/images/logo-gray.png" class="login-footer-logo" />
                    <img src="layout/images/appname-gray.png" class="login-footer-appname" />
                </div> -->
                <span>Copyright 2023</span>
            </div>
        </div>
    </div>
</template>

<script>
import { signInWithEmailAndPassword } from 'firebase/auth';
import { getCurrentUser, useFirebaseAuth } from 'vuefire';
export default {
    data() {
        return {
            email: '',
            password: '',
        };
    },
    computed: {
        loginColor() {
            if (this.$appState.colorScheme === 'light') return 'ondark';
            return 'onlight';
        },
        color() {
            if (this.$appState.colorScheme === 'light') return 'dark';
            return 'light';
        },
        itsNotProduction() {
            return process.env.NODE_ENV !== 'production';
        },
    },
    async mounted() {
        const currentUser = await getCurrentUser();
        if (currentUser) {
            const to = this.$route.query.redirect && typeof this.$route.query.redirect === 'string' ? this.$route.query.redirect : '/';
            this.$router.push(to);
        }
    },
    methods: {
        login() {
            const auth = useFirebaseAuth();
            signInWithEmailAndPassword(auth, this.email, this.password)
                .then(() => {
                    const to = this.$route.query.redirect && typeof this.$route.query.redirect === 'string' ? this.$route.query.redirect : '/';
                    this.$router.push(to);
                })
                .catch((error) => {
                    let toast = {};
                    switch (error.code) {
                        case 'auth/invalid-email':
                            toast.summary = 'Errore di autenticazione';
                            toast.detail = 'Email non valida';
                            break;
                        case 'auth/user-not-found':
                            toast.summary = 'Errore di autenticazione';
                            toast.detail = 'Utente non trovato';
                            break;
                        case 'auth/wrong-password':
                            toast.summary = 'Errore di autenticazione';
                            toast.detail = 'Password errata';
                            break;
                        default:
                            toast.summary = 'Errore di autenticazione';
                            toast.detail = 'Errore generico, contatta un amministratore';
                            break;
                    }
                    this.$toast.add({ severity: 'error', summary: toast.summary, detail: toast.detail, life: 3000 });
                    //console.error("Errore durante l'autenticazione:", error.code);
                });
        },
    },
};
</script>
<style scoped>
.password {
    display: block;
    max-width: 320px;
    min-width: 270px;
    margin-bottom: 10px;
}
</style>